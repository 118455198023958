.attachment-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 16px;
}

.chat-input-shadow {
	box-shadow: 0px -3px 48px -1px rgba(0, 0, 0, 0.1);
	background: #fff;
}
.chat-input {
	border: 2px solid #b0b0b0;
	border-right: none;
	border-left: none;
}

.icon {
	margin: 0% 3px;
	font-size: 20px;
}

.chat-input textarea {
	width: 100%;
	overflow: hidden;
	height: auto;
	border: 0px;
	padding: 15px 0px 15px 0px;
	resize: none;
	font-size: 15px;
	font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
	font-weight: 400;
	line-height: 1.33;
	color: #565867;
}

.chat-input .Dropzone {
	width: 40px;
	height: 40px;
	margin: 6px;
	overflow: hidden;
}

.react-giphy-select__src-components-GiphySelect-styles__select___25GhN {
	transform: scale(0.7);
	background-color: #fff;
	margin: -78px -50px;
}

.chat-app .flex,
.chat-app-admin .flex {
	display: flex;
}
.chat-app .flex-direction,
.chat-app-admin .flex-direction {
	flex-direction: row;
}
.chat-app .flex-direction-column,
.chat-app-admin .flex-direction-column {
	flex-direction: column;
}
.chat-app .row-reverse,
.chat-app-admin .row-reverse {
	flex-direction: row-reverse;
}
.chat-app .flex-bottom,
.chat-app-admin .flex-bottom {
	align-items: flex-end;
}
.chat-app .margin-left-minus,
.chat-app-admin .margin-left-minus {
	margin-left: -15px;
}
.chat-app .margin-left-minus:last-child,
.chat-app-admin .margin-left-minus:last-child {
	margin-left: 0;
}
.chat-app .margin-sm,
.chat-app-admin .margin-sm {
	margin: 5px !important;
}
.chat-app .margin-md,
.chat-app-admin .margin-md {
	margin: 10px !important;
}
.chat-app .space-between,
.chat-app-admin .space-between {
	justify-content: space-between;
}
.chat-app .space-around,
.chat-app-admin .space-around {
	justify-content: space-around;
}
.chat-app .justify-center,
.chat-app-admin .justify-center {
	justify-content: center;
}
.chat-app .items-center,
.chat-app-admin .items-center {
	align-items: center;
}
.chat-app .emoji-mart-bar,
.chat-app-admin .emoji-mart-bar {
	display: none;
}
.chat-app .interior-padding,
.chat-app-admin .interior-padding {
	padding: 30px;
}
.chat-app .interior-padding-20,
.chat-app-admin .interior-padding-20 {
	padding: 20px;
}
.chat-app .drop-shadow,
.chat-app-admin .drop-shadow {
	box-shadow: 0px 6px 30px -4px rgba(0, 0, 0, 0.45);
}
.chat-app .drop-shadow-hover:hover,
.chat-app-admin .drop-shadow-hover:hover {
	box-shadow: 0px 8px 30px -4px rgba(0, 0, 0, 0.55);
}
.chat-app .drop-shadow-hover,
.chat-app-admin .drop-shadow-hover {
	box-shadow: 0px 6px 30px -4px rgba(0, 0, 0, 0);
	transition: box-shadow 0.3s ease;
}
.chat-app .drop-shadow-hover-active,
.chat-app-admin .drop-shadow-hover-active {
	box-shadow: 0px 6px 30px -4px rgba(0, 0, 0, 0.45);
}
.chat-app .header-shadow,
.chat-app-admin .header-shadow {
	box-shadow: 0px -3px 42px 6px rgba(0, 0, 0, 0.17);
}
.chat-app .radius,
.chat-app-admin .radius {
	border-radius: 9px;
}
.chat-app .radius-bottom,
.chat-app-admin .radius-bottom {
	border-radius: 0 0 9px 9px;
}
.chat-app .hide,
.chat-app-admin .hide {
	visibility: hidden;
}
.chat-app .hide-display,
.chat-app-admin .hide-display {
	display: none;
}
.chat-app .opaque,
.chat-app-admin .opaque {
	opacity: 0.65;
}
.chat-app .relative,
.chat-app-admin .relative {
	position: relative;
}
.chat-app .w100,
.chat-app-admin .w100 {
	width: 100%;
}
.chat-app .h100,
.chat-app-admin .h100 {
	height: 100%;
}
.chat-app .opacity-0,
.chat-app-admin .opacity-0 {
	opacity: 0;
}
.chat-app .opacity-1,
.chat-app-admin .opacity-1 {
	opacity: 0.1;
}
.chat-app .opacity-2,
.chat-app-admin .opacity-2 {
	opacity: 0.2;
}
.chat-app .opacity-3,
.chat-app-admin .opacity-3 {
	opacity: 0.3;
}
.chat-app .opacity-4,
.chat-app-admin .opacity-4 {
	opacity: 0.4;
}
.chat-app .opacity-5,
.chat-app-admin .opacity-5 {
	opacity: 0.5;
}
.chat-app .opacity-6,
.chat-app-admin .opacity-6 {
	opacity: 0.6;
}
.chat-app .opacity-7,
.chat-app-admin .opacity-7 {
	opacity: 0.7;
}
.chat-app .opacity-8,
.chat-app-admin .opacity-8 {
	opacity: 0.8;
}
.chat-app .opacity-9,
.chat-app-admin .opacity-9 {
	opacity: 0.9;
}
.chat-app .opacity-10,
.chat-app-admin .opacity-10 {
	opacity: 1;
}
.chat-app .padding-all,
.chat-app-admin .padding-all {
	padding: 17px 20px;
}
.chat-app .padding-10,
.chat-app-admin .padding-10 {
	padding: 10px;
}
.chat-app .padding-20,
.chat-app-admin .padding-20 {
	padding: 20px;
}
.chat-app .padding-30,
.chat-app-admin .padding-30 {
	padding: 30px;
}
.chat-app .padding-40,
.chat-app-admin .padding-40 {
	padding: 40px;
}
.chat-app .padding-50,
.chat-app-admin .padding-50 {
	padding: 50px;
}
.chat-app .padding-v-20,
.chat-app-admin .padding-v-20 {
	padding: 20px 0;
}
.chat-app .padding-h-20,
.chat-app-admin .padding-h-20 {
	padding: 0 20px;
}
.chat-app .pointer,
.chat-app-admin .pointer {
	cursor: pointer;
}
.chat-app .pointer-events-none,
.chat-app-admin .pointer-events-none {
	pointer-events: none;
}
.chat-app .pointer-events-initial,
.chat-app-admin .pointer-events-initial {
	pointer-events: initial;
}
.chat-app .hover-gray:hover,
.chat-app-admin .hover-gray:hover {
	background-color: #f6f7f9;
}
.chat-app .background-gray,
.chat-app-admin .background-gray {
	background-color: #edf1f4;
}
.chat-app .background-blue,
.chat-app-admin .background-blue {
	background-color: #00bcd4;
}
.chat-app .background-darkgray,
.chat-app-admin .background-darkgray {
	background-color: #8196b2;
}
.chat-app .background-white,
.chat-app-admin .background-white {
	background-color: white;
}
.chat-app .white-1,
.chat-app-admin .white-1 {
	color: white !important;
}
.chat-app .text-opaque,
.chat-app-admin .text-opaque {
	color: rgba(255, 255, 255, 0.65);
}
.chat-app .text-center,
.chat-app-admin .text-center {
	text-align: center;
}
.chat-app .text-light,
.chat-app-admin .text-light {
	color: #737376;
}
.chat-app .gutter-left,
.chat-app-admin .gutter-left {
	margin-left: 10px;
}
.chat-app .right,
.chat-app-admin .right {
	float: right;
}
.chat-app .full-width,
.chat-app-admin .full-width {
	width: 100%;
}
.chat-app .flex-center,
.chat-app-admin .flex-center,
.chat-app .flex-vcenter,
.chat-app-admin .flex-vcenter {
	align-items: center;
}
.chat-app .flex-center,
.chat-app-admin .flex-center,
.chat-app .flex-hcenter,
.chat-app-admin .flex-hcenter {
	justify-content: center;
}
.chat-app .overflow-hidden,
.chat-app-admin .overflow-hidden {
	overflow: hidden;
}
.chat-app .overflow-scroll,
.chat-app-admin .overflow-scroll {
	overflow: scroll;
}
.chat-app .flex-right,
.chat-app-admin .flex-right {
	margin-left: auto;
}
.chat-app .fadeInUp,
.chat-app-admin .fadeInUp {
	animation: fadeInUp 0.4s ease 0s 1 normal;
	-webkit-transform: translateZ(0);
}
.chat-app .fadeInLeft,
.chat-app-admin .fadeInLeft {
	animation: fadeInLeft 0.4s ease 0s 1 normal;
	-webkit-transform: translateZ(0);
}
.chat-app *,
.chat-app-admin * {
	font-family: "Source Sans Pro", sans-serif;
}
.chat-app .chat__container,
.chat-app-admin .chat__container {
	position: fixed;
	font-family: "Open Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	font-size: 16px;
	line-height: 1.1em;
	font-weight: 400;
	bottom: 0;
	right: 0;
	width: 400px;
	height: 700px;
	padding: 20px;
	box-sizing: border-box;
	z-index: 2;
	/*pointer-events: none;*/
}
.chat-app .chat__container.closed,
.chat-app-admin .chat__container.closed {
	width: 100px !important;
	height: 100px !important;
}
.chat-app .chat__container div,
.chat-app-admin .chat__container div {
	box-sizing: border-box;
}
.chat-app .chat__container *:focus,
.chat-app-admin .chat__container *:focus {
	outline: none;
}
.chat-app .chat__container p,
.chat-app-admin .chat__container p {
	font-size: 14px;
	/* padding-top: 10px; */
	margin-top: 0;
	margin-bottom: 0;
}
.chat-app .chat__container h3,
.chat-app-admin .chat__container h3 {
	font-size: 16px;
	font-weight: 400;
	margin: 0;
}
.chat-app .chat__container .p,
.chat-app-admin .chat__container .p {
	font-size: 15px;
	font-weight: 400;
	margin: 0;
}
.chat-app .conversationHead,
.chat-app-admin .conversationHead {
	margin-left: 20px;
	margin-top: 30px;
	font-weight: 400;
	font-size: 17px;
	margin-bottom: 10px;
}
.chat-app .body,
.chat-app-admin .body {
	margin-top: -15px;
	overflow: auto;
	width: 320px;
	margin-left: 20px;
	background: white;
	border: 1px solid white;
	border-radius: 5px;
}
.chat-app .text-opaque,
.chat-app-admin .text-opaque {
	font-size: 28px;
	/* height: 45px; */
	margin-bottom: -17px;
}
.chat-app .panel,
.chat-app-admin .panel {
	position: relative;
	height: calc(100% - 80px);
	margin-bottom: 20px;
	border: 0;
	background-color: white;
}
.chat-app .panel .message-body,
.chat-app-admin .panel .message-body {
	height: calc(100% - 53px);
}
.chat-app .vue-dashboard .message-body,
.chat-app-admin .vue-dashboard .message-body {
	height: 100%;
}
.chat-app .panel .body,
.chat-app-admin .panel .body {
	height: calc(100% - 250px);
}
.chat-app .header,
.chat-app-admin .header {
	color: white;
	height: 80px;
	background-color: #00bcd4;
	transition: all 500ms;
}
.chat-app .header-padding,
.chat-app-admin .header-padding {
	height: 220px;
	padding-top: 16px;
	padding-left: 12px;
}
.chat-app .header-padding-chat,
.chat-app-admin .header-padding-chat {
	padding-top: 2px;
	padding-left: 12px;
}
.chat-app .material-icons,
.chat-app-admin .material-icons {
	/* padding-top: 10px; */
}
.chat-app .avatar,
.chat-app-admin .avatar {
	position: relative;
	width: 32px;
	min-width: 32px;
	height: 32px;
	min-height: 32px;
	margin-right: 0;
	border-radius: 50%;
	flex: 0 0 auto;
	background-size: contain;
	background-position: center;
	background-color: white;
}
.chat-app .avatarme,
.chat-app-admin .avatarme {
	position: relative;
	width: 22px;
	min-width: 22px;
	height: 22px;
	min-height: 22px;
	margin-right: 0;
	border-radius: 50%;
	flex: 0 0 auto;
	background-size: contain;
	background-position: center;
	background-color: white;
}
.chat-app .message-padding,
.chat-app-admin .message-padding {
	padding: 10px 24px;
}
.chat-app .padding-top-2,
.chat-app-admin .padding-top-2 {
	padding-top: 2px;
}
.chat-app .timeago,
.chat-app-admin .timeago {
	padding-top: 5px;
	color: #737376;
}
.chat-app .conversation-list,
.chat-app-admin .conversation-list {
	/* max-height: 240px !important; */
	height: 170px !important;
	/* height: 100%; */
	/* overflow-y: auto; */
	overflow-x: hidden !important;
	margin-bottom: 10px;
}
.chat-app .conversation-title,
.chat-app-admin .conversation-title {
	height: 40px;
	padding-top: 18px;
}
.chat-app .avatar-spacer,
.chat-app-admin .avatar-spacer {
	padding-left: 50px;
}
.chat-app .conversation,
.chat-app-admin .conversation {
	border-bottom: solid rgba(211, 211, 211, 0.31) 1px;
}
.chat-app .conversation-text-padding,
.chat-app-admin .conversation-text-padding {
	padding: 0 0 0 16px;
	width: calc(100% - 40px);
}
.chat-app .conversation-ago,
.chat-app-admin .conversation-ago {
	width: 120px;
	text-align: right;
}
.chat-app .conversation-button-wrapper,
.chat-app-admin .conversation-button-wrapper {
	position: absolute;
	margin-bottom: 20px;
	bottom: 0;
}
.chat-app .conversation-button,
.chat-app-admin .conversation-button {
	margin-top: 10px;
	background: white;
	border: 1px solid blue;
	color: blue !important;
	padding-bottom: 10px;
	margin-left: 6px;
	width: 200px;
	height: 50px;
	border-radius: 20px;
	text-align: center;
	color: white;
}
.chat-app .conversation-button p,
.chat-app-admin .conversation-button p {
	font-size: 16px;
}
.chat-app .conversation-header,
.chat-app-admin .conversation-header {
	padding-left: 10px;
	padding-top: 10px;
	height: 250px;
}
.chat-app .back-button,
.chat-app-admin .back-button {
	padding: 12px;
	margin: 15px 7px 15px 7px;
	height: 50px;
}
.chat-app .back-button .material-icons,
.chat-app-admin .back-button .material-icons {
	padding: 0;
}
.chat-app .back-button:hover,
.chat-app-admin .back-button:hover {
	background-color: rgba(0, 0, 0, 0.09);
	transition: 0.3s ease background-color;
}
.chat-app .button,
.chat-app-admin .button,
.chat-app .mobile-button,
.chat-app-admin .mobile-button {
	border-radius: 50%;
	float: right;
	width: 60px;
	height: 60px;
	text-align: center;
	color: white;
	background-color: #00bcd4;
}
.chat-app .mobile-button-close,
.chat-app-admin .mobile-button-close {
	position: absolute;
	right: 20px;
	top: 30px;
	float: right;
	width: 30px;
	height: 30px;
	text-align: center;
	color: white;
	font-size: 30px;
	display: none;
}
.chat-app .fadeInLeft,
.chat-app-admin .fadeInLeft {
	font-size: 22px;
}
.chat-app .rotate-toggle-button,
.chat-app-admin .rotate-toggle-button {
	animation: rotate 0.4s ease 0s 1 normal;
}
@media screen and (max-height: 700px) {
	.chat-app .chat__container,
	.chat-app-admin .chat__container {
		height: 100%;
	}
	.chat-app .conversation-list,
	.chat-app-admin .conversation-list {
		height: calc(calc(var(--vh, 1vh) * 100) - 450px) !important;
	}
}
@media screen and (max-height: 450px) {
	.chat-app .chat__container,
	.chat-app-admin .chat__container {
		height: 450px;
	}
}
@media screen and (max-width: 450px) {
	.chat-app *:hover,
	.chat-app-admin *:hover {
		box-shadow: none !important;
	}
	.chat-app .body,
	.chat-app-admin .body {
		width: calc(100vw - 40px);
	}
	.chat-app .chat__container,
	.chat-app-admin .chat__container {
		height: 100%;
		width: 100%;
		padding: 0;
	}
	.chat-app .panel,
	.chat-app-admin .panel {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 0;
		margin-bottom: 0px;
		height: 100%;
		z-index: 22222222;
	}
	.chat-app .button,
	.chat-app-admin .button {
		position: absolute;
		bottom: 0;
		right: 0;
		margin: 20px;
	}
	.chat-app .mobile-button-close,
	.chat-app-admin .mobile-button-close {
		display: block;
	}
	.chat-app .conversation-list,
	.chat-app-admin .conversation-list {
		height: calc(calc(var(--vh, 1vh) * 100) - 385px) !important;
	}
}
@-webkit-keyframes fadeInUp {
	0% {
		-webkit-transform: translateY(15px);
		transform: translateY(15px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes fadeInUp {
	0% {
		transform: translateY(15px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@-webkit-keyframes fadeInLeft {
	0% {
		-webkit-transform: translateX(15px);
		transform: translateX(15px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes fadeInLeft {
	0% {
		transform: translateX(15px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(90deg);
	}
}
.chat-app .conversation-list.NoItem,
.chat-app-admin .conversation-list.NoItem {
	/* height: 130px !important; */
}
.chat-app .conversation-list.NoItem > div,
.chat-app-admin .conversation-list.NoItem > div {
	padding-right: 20px;
	padding-left: 20px;
}

/* src/ImagePicker.css or your custom stylesheet */

/* Image picker container styles */
.image-picker-container {
    border: 2px dashed #ccc;
    padding: 20px;
    cursor: pointer;
    position: relative;
    max-width: 300px;
    margin: 0 auto;
  }
  
  /* Selected image styles */
  .selected-image {
    max-width: 100%;
    max-height: 200px;
    display: block;
    margin: 10px auto;
  }
  
  /* Placeholder text styles */
  .placeholder-text {
    text-align: center;
    color: #888;
  }
  
  /* Hide the file input */
  .image-picker-input {
    display: none;
  }
  
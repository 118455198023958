.chat-app .chat-container {
	overflow-y: hidden;
	overflow-x: hidden;
}

.chat-app .upload-image-indicator {
	margin: 20px 0 0 6px;
	color: rgba(0, 0, 0, 0.2);
	font-size: 14px;
}

.chat-app .dropzone {
	height: calc(100% - 80px);
	transition: all 500ms;
}

.chat-app .dropzone.new {
	height: calc(100% - 250px);
}

.chat-app .loading-container {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin-top: 100px;
}

.chat-app .loading {
	border-radius: 100%;
	animation: sk-scaleout 1s infinite ease-in-out;
	width: 30px;
	height: 30px;
}

.chat-app .message-body {
	height: calc(100% - 53px);
}

@keyframes sk-scaleout {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}
